<template>
  <section class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>AIOT</el-breadcrumb-item>
           <el-breadcrumb-item>酒店管理</el-breadcrumb-item>
           <el-breadcrumb-item>样板房</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
     <el-row class="content-box">
        <!-- 主体内容 -->
        <el-row class="table-box">
           <!-- 表格按钮 -->
           <div class="table-head-btn">
              <el-row><h6 class="font-18">样板房列表</h6></el-row>
              <el-row class="right-btn">
                 <el-button class="bg-gradient" type="primary" icon="el-icon-circle-plus" @click="handleAdd" >添加样板房</el-button>
              </el-row>
           </div>
           <!-- 表格 -->
           <el-table
                 ref="singleTable"
                 v-loading="loading"
                 :data="tableData"
                 border fit
                 highlight-current-row
                 style="width: 100%;"
                 :stripe="true"
                 >
              <el-table-column :label="$t('msg.number')" width="60">
                 <template slot-scope="scope">
                    {{scope.$index + 1}}
                 </template>
              </el-table-column>
              <el-table-column prop="templateName" :label="$t('msg.dExamp_name')"></el-table-column>
              <el-table-column prop="templateCode" :label="$t('msg.dExamp_id')"></el-table-column>
              <el-table-column prop="roomTypeName" :label="$t('msg.room_type')"></el-table-column>
              <el-table-column prop="roomNo" :label="$t('msg.room_num')"></el-table-column>
              <el-table-column prop="hotelName" :label="$t('msg.own_hotel')" min-width="110"></el-table-column>
              <el-table-column prop="buildUnit" :label="$t('msg.building_unit')" min-width="110"></el-table-column>
              <el-table-column :label="$t('msg.operating')" width="200"  fixed="right">
                 <template slot-scope="scope">
                    <el-button  type="text" icon="el-icon-edit" @click="handleEdit(scope.row)" >
                       <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                    </el-button>
                    <el-button  type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="handleDel(scope.row.id)" >
                       <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                    </el-button>
                 </template>
              </el-table-column>
           </el-table>
           <!-- 分页 -->
           <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="currChange"/>
        </el-row>
        <!-- 添加/编辑样板房 -->
         <el-dialog :title="$t('msg.add_dExamp')" :visible.sync="isShow" width="550px" :before-close="handleClose">
            <el-form class="form-dialog-box">
               <el-form-item required label="楼栋/单元">
                  <el-cascader
                     :key="buildKey"
                     v-model="buildUnitId"
                     :options="buildList"
                     @change="changeBuild()"
                     clearable
                     class="width-300"></el-cascader>
               </el-form-item>
               <el-form-item required label="样板房名称">
                  <el-input  class="width-300" v-model="templateName" :placeholder="$t('msg.dExamp_name')" clearable></el-input>
               </el-form-item>
               <el-form-item required label="样板房编号">
                  <el-input  class="width-300" v-model="templateCode" :placeholder="$t('msg.dExamp_id')" clearable></el-input>
               </el-form-item>
               <el-form-item required label="房间类型">
                  <el-select class="width-300" v-model="roomTypeId" @change="changeRoomType" :placeholder="$t('msg.select')"  clearable>
                     <el-option v-for="(item,index) in roomTypes" :key="index" :label="item.label" :value="item.value"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item required label="房间号">
                   <el-select class="width-300" v-model="roomId" :placeholder="$t('msg.select')"  filterable clearable>
                     <el-option v-for="item in roomNums" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="备注">
                  <el-input class="width-300 type-textarea" type="textarea" v-model="remark" :placeholder="$t('msg.inp_remark_info')"></el-input>
               </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
               <el-button  @click="handleReset()" v-text="$t('msg.reset')">重置</el-button>
               <el-button class="bg-gradient" type="primary" @click="handleSave()" v-text="$t('msg.save')">保存</el-button>
            </span>
         </el-dialog>
     </el-row>
  </section>
</template>
<script>
   import { urlObj } from '@/api/interface'
   import common from '@/common/js/common'
   import { mapState } from 'vuex'
   export default {
      computed:{
         ...mapState(['hotelInfo'])
      },
      watch:{
         hotelInfo:{
            handler(newVal,oldVal){
               if(newVal.id !== oldVal.id && oldVal){
                  this.hotelId = this.hotelInfo.id
                  this.getSampleHouses()
               }
            }
         },
         buildList(newVal){
            if(newVal.length !== 0) this.buildKey = newVal[0].value
         }
      },
      data () {
         return {
            tableData: [],     // 表格数据
            buildList: [],     // 楼栋/单元列表
            roomTypes: [],     // 房型列表
            roomNums: [],      // 房号列表
            sideLoad: true,    // 侧边栏加载效果
            loading: true,     // 表格加载效果
            total: 0,          // 数据总数
            isShow: false,     // 是否显示弹窗
            buildUnitId: [],   // 楼栋IDs
            operWidth: 0,      // 编辑分栏宽度
            // boxIcon: true,     // 酒店显示
            buildKey: '',      // 联级器索引
            /* 查询列表参数 */
            hotelId: '',       // 酒店ID
            limit: 1,          // 每页数
            page: 1,           // 当前页
            /* 提交表单字段 */
            templateName: '',  // 样板房名称
            templateCode: '',  // 样板房编号
            roomTypeId: '',    // 房型ID
            roomId: '',        // 房间ID
            remark: '',        // 备注
            /* 提示文本 */
            confirm: '确定',
            cancel: '取消',
            add_success: '添加成功！',
            update_success: '修改成功！',
            prompt: '提示！',
            confirm_edit: '确定修改？',
            confirm_remove: '确定移除？',
            confirm_remove_hotel: '若删除酒店，将删除其下面所有的部门、人员数据，确定删除？',
            remove_success: '移除成功！',
            bind_success: '绑定成功！',
            room_bind_no_null: '请选择要绑定的房型！',
            building_unit_no_null: '楼栋/单元不能为空！',
            examp_name_no_null: '样板房名称不能为空！',
            examp_id_no_null: '样板房编号不能为空！',
            room_type_no_null_a: '房间类型不能为空！',
            floor_no_null: '楼层不能为空！',
            dev_location_no_null: '设备位置不能为空！',
            room_no_null: '房间号不能为空！',
         }
      },
      mounted(){
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.hotelId = this.hotelInfo.id
         this.getSampleHouses()
      },
      methods: {
         // 样板房列表
         getSampleHouses(){
            let url = urlObj.getRoomTemplatePages
            let param = {
               limit: this.limit,
               page: this.page,
               hotelId: this.hotelId
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.loading = false
                  this.total = res.total
                  let tableData = res.records
                  tableData.forEach(item => item.buildUnit = item.buildName + '/' + item.unitName)
                  this.tableData = tableData
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 改变每页数
         pageChange(num){
            this.limit = num
            this.getSampleHouses()
         },
         // 改变当前页
         currChange(num){
            this.page = num
            this.getSampleHouses()
         },
         // 添加样板房
         handleAdd(){
            this.isShow = true
            this.action = 'add'
            this.getBuildings()
            this.getRoomTypes()
         },
         // 编辑样板房
         handleEdit(row){
            this.isShow = true
            this.action = 'edit'
            this.id = row.id
            this.buildUnitId.push(row.buildId, row.unitId)
            this.templateName = row.templateName
            this.templateCode = row.templateCode
            this.roomTypeId = row.roomTypeId
            this.roomId = row.roomId
            this.remark = row.remark
            this.getBuildings()
            this.getRoomTypes()
            this.getRoomNums()
         },
         // 获取楼栋单元列表
         getBuildings(){
            const url = urlObj.buildAndUnitList + `/${this.hotelId}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  let buildList = res.records
                  this.buildList = buildList.map(item1 => {
                     let children = []
                     if (item1.buildUnits.length > 0){
                        children = item1.buildUnits.map(item2 => ({value: item2.id, label: item2.unitName}))
                     }
                     return {value: item1.id, label: item1.buildName, children}
                  })
               }
            })
         },
         // 改变当前楼栋
         changeBuild(){
            this.roomId = ''
            this.getRoomNums()
         },
         // 获取房型列表
         getRoomTypes(){
            const url = urlObj.getHotelRoomTypeList
            const param = {
               hotelId: this.hotelId
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let roomTypes = res.records
                  this.roomTypes = roomTypes.map(item => {
                     return {value: item.roomTypeId, label: item.roomTypeName}
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 改变当前房型
         changeRoomType(){
            this.roomId = ''
            this.getRoomNums()
         },
         // 获取房号列表
         getRoomNums(){
            const url = urlObj.getRoomLists
            const param = {
               hotelId: this.hotelId,
               roomTypeId: this.roomTypeId,
               buildUnitId: this.buildUnitId[1]
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let roomNums = res.records
                  this.roomNums = roomNums.map(item => {
                     return {value: item.id, label: item.roomNo}
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 重置表单
         handleReset(){
            this.buildUnitId = []
            this.templateName = ''
            this.templateCode = ''
            this.roomTypeId = ''
            this.roomId = ''
            this.remark = ''
         },
         // 保存操作
         handleSave(){
            // 表单验证
            if (!this.templateName) {
               this.$alert(this.examp_name_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            if (!this.templateCode) {
               this.$alert(this.examp_id_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            if (!this.roomTypeId) {
               this.$alert(this.room_type_no_null_a, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            if (!this.roomId) {
               this.$alert(this.room_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return false
            }
            let url = urlObj.addRoomTemplate
            let param = {
               hotelId: this.hotelId,
               templateName: this.templateName,
               templateCode: this.templateCode,
               roomTypeId: this.roomTypeId,
               roomId: this.roomId,
               remark: this.remark
            }
            if (this.action === 'edit') {
               param.id = this.id
               url = urlObj.editRoomTemplate
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.action === 'add' ? this.add_success : this.update_success,
                     type: 'success'
                  })
                  this.getSampleHouses()
                  this.handleClose()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 关闭弹窗
         handleClose(){
            this.isShow = false
            this.handleReset()
         },
         // 删除样板房
         handleDel(id){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(() => {
               let url = urlObj.deleteRoomTemplate + `/${id}`
               this.$axios.get(url, {}).then((res) => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.remove_success,
                        type: 'success'
                     })
                     this.getSampleHouses()
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            })
         },
      }
   }
</script>
<style scoped lang="scss"></style>
